import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "line-update" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!

  return (_openBlock(), _createBlock(_component_ModalCommon, {
    textButton: "Crear",
    onEventSuccess: _ctx.saveVehicle,
    "max-width": "700",
    isVisible: _ctx.isVisible,
    title: _ctx.updateType == 'type-vehicle' ? 'Tipo de vehículo' : _ctx.updateType == 'brand' ? 'Marca' : 'Modelos',
    loading: _ctx.loadingCreateVehicle,
    onCloseModal: _ctx.closeModalCreateVehicle
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_btn, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setTypeCreated('type-vehicle'))),
            variant: _ctx.updateType == 'type-vehicle' ? 'elevated' : 'outlined',
            color: "orange"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.updateType == 'type-vehicle' ? 'text-white' : '')
              }, "Tipo de vehículo", 2)
            ]),
            _: 1
          }, 8, ["variant"]),
          _createVNode(_component_v_btn, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTypeCreated('brand'))),
            variant: _ctx.updateType == 'brand' ? 'elevated' : 'outlined',
            color: "blue"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.updateType == 'brand' ? 'text-white' : '')
              }, "Marcas", 2)
            ]),
            _: 1
          }, 8, ["variant"]),
          _createVNode(_component_v_btn, {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTypeCreated('model'))),
            variant: _ctx.updateType == 'model' ? 'elevated' : 'outlined',
            color: "green"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.updateType == 'model' ? 'text-white' : '')
              }, "Modelos", 2)
            ]),
            _: 1
          }, 8, ["variant"])
        ]),
        (_ctx.updateType == 'type-vehicle')
          ? (_openBlock(), _createBlock(_component_v_form, {
              key: 0,
              ref: "formCreateVehicle"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { "no-gutters": "" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      class: "px-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.dataCreate.name,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataCreate.name) = $event)),
                          variant: "outlined",
                          density: "comfortable",
                          label: "Nombre",
                          rules: [_ctx.requiredField]
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      lg: "6",
                      class: "px-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.dataCreate.seatings,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataCreate.seatings) = $event)),
                          variant: "outlined",
                          onKeypress: _ctx.validateNumber,
                          density: "comfortable",
                          label: "Asientos"
                        }, null, 8, ["modelValue", "onKeypress"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512))
          : (_ctx.updateType == 'brand')
            ? (_openBlock(), _createBlock(_component_v_form, {
                key: 1,
                ref: "formCreateBrand"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        "offset-lg": "3",
                        lg: "6",
                        class: "px-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            modelValue: _ctx.dataCreate.name,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataCreate.name) = $event)),
                            variant: "outlined",
                            density: "comfortable",
                            label: "Nombre",
                            rules: [_ctx.requiredField]
                          }, null, 8, ["modelValue", "rules"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 512))
            : (_ctx.updateType == 'model')
              ? (_openBlock(), _createBlock(_component_v_form, {
                  key: 2,
                  ref: "formCreateModel"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          class: "px-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_select, {
                              items: _ctx.brands,
                              "item-title": "name",
                              "item-value": "_id",
                              modelValue: _ctx.dataCreatedModel.brand,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataCreatedModel.brand) = $event)),
                              variant: "outlined",
                              density: "comfortable",
                              label: "Marca",
                              rules: [_ctx.requiredField]
                            }, null, 8, ["items", "modelValue", "rules"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          lg: "6",
                          class: "px-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: _ctx.dataCreatedModel.name,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataCreatedModel.name) = $event)),
                              variant: "outlined",
                              density: "comfortable",
                              label: "Nombre",
                              rules: [_ctx.requiredField]
                            }, null, 8, ["modelValue", "rules"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512))
              : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onEventSuccess", "isVisible", "title", "loading", "onCloseModal"]))
}