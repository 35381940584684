import { useMainStore } from "@/store/modules/main";
import { DateTime } from "luxon";

export function formatDateCompleted(value: string) {
  if (!value) return "Sin datos";
  return new Intl.DateTimeFormat(undefined, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: true,

  }).format(new Date(value));
}

export function formatDate(value: string) {
  if (!value) return "Sin datos";
  return new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(new Date(value));
}

export function formatDateSimple(value: string | Date) {
  if (!value) return "Sin datos";
  return new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
  }).format(new Date(value));
}

export function getDate(
  value?: string | number,
  format: "onlyDate" | "complete" | "onlyHours" = "complete",
  type: "STRING" | "NUMBER" = "STRING",
  onlyNumberFormat = true
) {
  try {
    if(value == null || value == undefined){
      return '-'
    }
    const store = useMainStore();
    const formatDateStart =
      type === "STRING"
        ? DateTime.fromISO(value as string)
        : DateTime.fromMillis(value as number)
            .setZone(store.timeZone)
            .endOf("day");
    if (format == "onlyDate") {
      return formatDateStart.toFormat(
        onlyNumberFormat ? "dd/MM/yyyy" : "dd LLL. yyyy",
        { locale: "es" }
      );
    }
    if (format == "onlyHours") {
      return convertTo12HourFormat(
        formatDateStart.toFormat("HH:mm", { locale: "es" })
      );
    }
    if (format === "complete") {
      return formatDateStart.toFormat("yyyy/MM/dd hh:mm:ss");
    }
  } catch (error) {
    console.error(error)
    return '-'
  }
}

export const formatDateStart = (date: string) => {
  const store = useMainStore();
  return DateTime.fromISO(date).setZone(store.timeZone).endOf("day");
};
export const formatDateNoonJSDate = (date: Date) => {
  const store = useMainStore();
  return DateTime.fromJSDate(date)
    .setZone(store.timeZone)
    .set({ hour: 12, minute: 0, second: 0 });
};

export const formatDateStartJSDate = (date: Date) => {
  const store = useMainStore();
  return DateTime.fromJSDate(date).setZone(store.timeZone).endOf("day");
};
export const formatDateStartTimestamp = (date: number) => {
  const store = useMainStore();
  return DateTime.fromMillis(date).setZone(store.timeZone).endOf("day");
};

export const formatDateNoonTimestamp = (date: number) => {
  const store = useMainStore();
  return DateTime.fromMillis(date)
    .setZone(store.timeZone)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
};

export function convertTo12HourFormatV2(date: string){
  const dt = DateTime.fromISO(date)
  return dt.toFormat('HH:mm')
}

export function convertTo12HourFormat(time?: string): string {
  if (!time) return "";
  return `${time} hrs.`
 /*  const dt = DateTime.fromFormat(time, "HH:mm");
  const pmam = dt.toFormat("a").split("");
  if (pmam.length === 0) return "";
  return (
    dt.toFormat("hh:mm") +
    " " +
    pmam[0].toLocaleLowerCase() +
    "." +
    pmam[1].toLocaleLowerCase()
  ); */
}

export const getDateStarDay = (date: Date) => {
  const store = useMainStore();
  return DateTime.fromJSDate(date).setZone(store.timeZone).startOf("day");
};


export const formatDateMS = (date: string) =>{
  const store = useMainStore();
  return DateTime.fromJSDate(new Date(date)).setZone(store.timeZone).toFormat('yyyy-MM-dd hh:mm:uu.SSS')
}