
import { SectorApi } from '@/api/sector';
import { VehicleAPI } from '@/api/vehicles';
import ModalCommon from '@/components/common/ModalCommon.vue';
import { FormVuetify } from '@/types/common/vuetify';
import { CreateSectorEntity, CreateSectorHourEntity, CreateSectorHourVehicleEntity } from '@/types/request/location';
import { GetTypesVehicleResponse } from '@/types/response/vehicle';
import { formatCurrency, getErrorCatch, requiredField, sleep, validateNumber } from '@/utils';
import { Options, Vue } from 'vue-class-component';


@Options({
    name: 'SectorsAddView',
    components: {
        ModalCommon
    }
})
export default class SectorsAddView extends Vue {
    public dataCSV: File[] = [];
    public selectedImage: File | null = null
    private sectorApi = new SectorApi()
    public requiredField = requiredField;
    public validateNumber = validateNumber;
    public dataAddVehicle: CreateSectorHourVehicleEntity = {
        duration: null,
        price: null,
        typeVehicle: null
    }
    public isLoadingCreated = false
    public modalVisibleVehicle = false
    public messageLoading = ''
    public dataCreatedSectorSelected: CreateSectorEntity | null = null;
    public dataCreatedSectorKey: number | null = null
    public dataCreatedSector: CreateSectorEntity[] = []
    public typesVehicle: GetTypesVehicleResponse[] = []
    private vehicleApi = new VehicleAPI();
    public timeout = 0
    public colors: string[] = [
        "0, 123, 255, 0.5",
        "40, 167, 69, 0.5",
        "255, 193, 7, 0.5",
        "255, 87, 34, 0.5",
        "255, 0, 0, 0.5",
        "156, 39, 176, 0.5",
        "0, 188, 212, 0.5",
        "244, 143, 177, 0.5",
        "158, 158, 158, 0.5",
        "64, 224, 208, 0.5"
    ];

    beforeUnmount(): void {
        clearInterval(this.timeout)
        clearTimeout(this.timeout)
    }

    onFileChange(files: File[]) {
        const file = files[0]
        if (file instanceof File) {
            this.selectedImage = file;
        } else {
            console.error("El archivo seleccionado no es válido.");
            this.selectedImage = null;
        }
    }

    isValid24HourTime(range: string): string | null {
        const timeRangeRegex = /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/;
        const match = range.match(timeRangeRegex);
        if (!match) {
            return "El formato debe ser 'HH:mm - HH:mm' con horas en formato 24 horas.";
        }

        const startHour = parseInt(match[1], 10);
        const startMinute = parseInt(match[2], 10);
        const endHour = parseInt(match[3], 10);
        const endMinute = parseInt(match[4], 10);

        if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) {
            return "La hora de inicio debe ser menor que la hora final.";
        }

        return null;
    }

    async getTypesVehicle() {
        try {
            const typesVehicle = await this.vehicleApi.getTypesVehicleAiport();
            this.typesVehicle = typesVehicle.map(({_id,name,seating}) => {
                return {
                    _id,
                    name: `${name} (${seating})`
                }
            })
        } catch (error) {
            getErrorCatch(error);
        }
    }

    async processCSV() {
        const form = this.$refs['formSectorFile'] as FormVuetify;
        const validate = await form.validate()
        if (!validate.valid) {
            return;
        }
        if (this.selectedImage == null) {
            return;
        }
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (!e.target) {
                return;
            }
            const text: string = e.target.result as string;
            const lines = text.split("\n").filter((line) => line.trim() !== "");
            const dataSectors: CreateSectorEntity[] = []
            lines.slice(1).forEach((line) => {
                const [wkt, nombre] = line.match(/(".*?"|[^,]+)(?=\s*,|\s*$)/g) as string[];
                const points = wkt.replace(/"/g, "").replace("POLYGON ((", "").replace("))", "").split(", ").map(coord => {
                    const [lng, lat] = coord.split(" ").map(Number);
                    return [lng, lat];
                });
                dataSectors.push({
                    hours: [],
                    name: nombre.trim(),
                    points
                })
            });
            this.dataCreatedSector = dataSectors;
        }
        reader.readAsText(this.selectedImage);
    }

    addRange(value: CreateSectorEntity, key: number) {
        try {
            this.setDataHourSector(key, value, true)
            if (!value.tempHour || value.tempHour == '') {
                value.errorHour = true;
                value.messageError = "Campo requerido"
                this.setDataHourSector(key, value)
                return;
            }
            const isValidTempHour = this.isValid24HourTime(value.tempHour.trim());
            if (typeof isValidTempHour == 'string') {
                value.errorHour = true;
                value.messageError = isValidTempHour;
                this.setDataHourSector(key, value)
                return;
            }
            const [start, end] = value.tempHour.trim().split("-").map((time) => time.trim());
            if (this.isOverlapping(value, start, end)) {
                alert("El rango ingresado se superpone con uno existente.")
                return;
            }
            const [startHours, startMinutes] = start.split(":").map(Number);
            const [endHours, endMinutes] = end.split(":").map(Number);

            const startDeg = this.calculateDegrees(startHours % 12, startMinutes);
            const endDeg = this.calculateDegrees(endHours % 12, endMinutes);

            const isAMStart = startHours < 12;
            const isAMEnd = endHours < 12;

            const refAm = (this.$refs[`sectors-am${key}`] as HTMLElement[])[0]
            const refPm = (this.$refs[`sectors-pm${key}`] as HTMLElement[])[0]
            if (!refAm) {
                throw new Error('No se ha podido crear el sector, contacta con el admin.')
            }
            if (!refPm) {
                throw new Error('No se ha podido crear el sector, contacta con el admin.')
            }
            const color = value.tempIsBlocked ? '255, 0, 0, 1' : this.colors[value.hours.length]
            if (isAMStart && isAMEnd) {
                this.createSector(refAm, startDeg, endDeg, color, key, value.hours.length);
            } else if (!isAMStart && !isAMEnd) {
                this.createSector(refPm, startDeg, endDeg, color, key, value.hours.length);
            } else {
                this.createSector(refAm, startDeg, 360, color, key, value.hours.length); // Completa en AM
                this.createSector(refPm, 0, endDeg, color, key, value.hours.length); // Inicia en PM
            }
            value.hours.push({
                hourFinish: end,
                hourMin: start,
                isBlocked: value.tempIsBlocked || false,
                vehicles: []
            })
        } catch (error) {
            getErrorCatch(error)
        }
    }

    setDataHourSector(keyEntry: number, valueEntry: CreateSectorEntity, reset = false) {
        this.dataCreatedSector = this.dataCreatedSector.map((value, key) => {
            if (reset) {
                return {
                    ...value,
                    errorHour: false,
                    messageError: '',
                    tempHour: '',
                    tempIsBlocked: false
                }
            }
            if (key == keyEntry) {
                return valueEntry
            }
            return value
        })
    }

    calculateDegrees(hours: number, minutes: number) {
        const totalMinutes = hours * 60 + minutes;
        return (totalMinutes / (12 * 60)) * 360;
    }

    toMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }
    isOverlapping(data: CreateSectorEntity, newStart: string, newEnd: string) {
        const newStartMinutes = this.toMinutes(newStart);
        const newEndMinutes = this.toMinutes(newEnd);
        return data.hours.some((range) => {
            const rangeStartMinutes = this.toMinutes(range.hourMin);
            const rangeEndMinutes = this.toMinutes(range.hourFinish);
            return (
                (newStartMinutes >= rangeStartMinutes && newStartMinutes < rangeEndMinutes) ||
                (newEndMinutes > rangeStartMinutes && newEndMinutes <= rangeEndMinutes) ||
                (newStartMinutes <= rangeStartMinutes && newEndMinutes >= rangeEndMinutes)
            );
        }
        );
    }

    createSector(clock: HTMLElement, startDeg: number, endDeg: number, color = "0, 123, 255, 0.5", key: number, keyChildren: number) {
        const sector = document.createElement("div");
        sector.id = `hour${key}_${keyChildren}`
        sector.style.position = "absolute";
        sector.style.width = "100%";
        sector.style.height = "100%";
        sector.style.borderRadius = "50%";
        sector.style.background = `conic-gradient(
            transparent ${startDeg}deg,
            rgba(${color}) ${startDeg}deg,
            rgba(${color}) ${endDeg}deg,
            transparent ${endDeg}deg
        )`;
        clock.appendChild(sector);
    }

    deleteHour(data: CreateSectorEntity, keyMain: number, keyHour: number) {
        try {
            data.hours = data.hours.filter((hour, key) => key !== keyHour);
            const elements = document.querySelectorAll(`#hour${keyMain}_${keyHour}`)
            if (elements.length > 0) {
                elements.forEach((element) => {
                    element.remove()
                })
            } else {
                throw new Error("Ocurrio un error al eliminar")
            }
        } catch (error) {
            getErrorCatch(error)
        }
    }

    addTypeVehicle(data: CreateSectorEntity, keyHour: number) {
        this.dataCreatedSectorSelected = data;
        this.modalVisibleVehicle = true;
        this.dataCreatedSectorKey = keyHour
    }


    mounted() {
        this.getTypesVehicle()
    }

    deleteHouSector(keyVehicle: number, keyHour: number) {
        if (this.dataCreatedSectorSelected == null) {
            return;
        }
        this.dataCreatedSectorSelected.hours[keyHour].vehicles = this.dataCreatedSectorSelected.hours[keyHour].vehicles.filter((item, key) => key !== keyVehicle);
    }

    removeSector(data: CreateSectorEntity) {
        this.dataCreatedSector = this.dataCreatedSector.filter(({ name }) => name !== data.name);
    }

    async addHourSector() {
        try {
            const form = this.$refs['addHourSector'] as FormVuetify
            if (!form) {
                return
            }
            const { valid } = await form.validate()
            if (valid && this.dataCreatedSectorSelected !== null && this.dataCreatedSectorKey !== null) {
                const vehicles = this.dataCreatedSectorSelected.hours[this.dataCreatedSectorKey].vehicles;
                if (vehicles.find(({ typeVehicle }) => typeVehicle == this.dataAddVehicle.typeVehicle)) {
                    throw new Error('Tipo de vehículo ya registrado')
                }
                this.dataCreatedSectorSelected.hours[this.dataCreatedSectorKey].vehicles.push({
                    duration: parseInt((this.dataAddVehicle.duration as string) || "0"),
                    price: parseInt((this.dataAddVehicle.price as string) || "0"),
                    typeVehicle: this.dataAddVehicle.typeVehicle
                })
                this.dataAddVehicle = {
                    duration: null,
                    price: null,
                    typeVehicle: null
                }
            }
        } catch (error) {
            this.dataAddVehicle = {
                duration: null,
                price: null,
                typeVehicle: null
            }
            getErrorCatch(error)
        }
    }
    getNameVehicle(id: string | null) {
        if (id == null) {
            return 'NN'
        }
        const typeVehicle = this.typesVehicle.find(({ _id }) => _id == id);
        if (typeVehicle) {
            return typeVehicle.name
        }
        return 'NN'
    }
    formatCurrency(value: string | number) {
        return formatCurrency(parseInt(value as string))
    }

    async saveSectors() {
        try {
            const hoursEmpty = this.dataCreatedSector.filter(({ hours }) => hours.length == 0)
            if (hoursEmpty.length > 0) {
                throw new Error('Sectores no tienen horas asignadas')
            }
            let errors = []
            for (let index = 0; index < this.dataCreatedSector.length; index++) {
                const element = this.dataCreatedSector[index];
                const hoursElement = element.hours.map((h) => ({ hourMin: h.hourMin, hourFinish: h.hourFinish }));
                const data = this.findMissingIntervals(hoursElement)
                if(data.length> 0){
                    errors.push(`Al sector ${element.name} le falta los siguientes rangos horarios: ${data.map(({hourFinish,hourMin}) => `${hourMin} - ${hourFinish}`).join(", ")}`)
                }
                const vehiclesEmpty = element.hours.filter(({isBlocked}) => !isBlocked).filter(({vehicles}) => vehicles.length == 0);
                if(vehiclesEmpty.length >0){
                    errors.push(`Al sector ${element.name} le falta agregar vehículos a sus rangos horarios`)
                }
            }
            if(errors.length > 0){
                throw new Error(errors.join(", "));
            }
            this.isLoadingCreated = true;
            for (let index = 0; index < this.dataCreatedSector.length; index++) {
                const element = this.dataCreatedSector[index];
                try {
                    this.messageLoading = `Creando el sector <b>${element.name}</b>`
                    await this.sectorApi.create(element);
                    if(index>0){
                        await sleep(3000,this.timeout)
                    }
                    this.messageLoading = `Sector <b>${element.name}</b> creado correctamente.`
                    await sleep(1000,this.timeout)
                } catch (error) {
                    this.messageLoading = `Ocurrio un error creando el sector ${element.name}`
                }
            }
            this.messageLoading = "Redirigiendo a sectores...";
            await sleep(1000,this.timeout);
            this.$router.push("/sectores")
            this.isLoadingCreated = false;
        } catch (error) {
            getErrorCatch(error)
        }
    }
    

    timeToMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }
    minutesToTime(minutes: number) {
        const hours = Math.floor(minutes / 60).toString().padStart(2, "0");
        const mins = (minutes % 60).toString().padStart(2, "0");
        return `${hours}:${mins}`;
    }

    findMissingIntervals(timeIntervals: { hourMin: string, hourFinish: string }[]) {
        const fullDayStart = "00:00";
        const fullDayEnd = "23:59";
        const sortedIntervals = timeIntervals
            .map(interval => ({
                hourMin: this.timeToMinutes(interval.hourMin),
                hourFinish: this.timeToMinutes(interval.hourFinish),
            }))
            .sort((a, b) => a.hourMin - b.hourMin);
        const missingIntervals: { hourMin: string, hourFinish: string }[] = [];
        let currentTime = this.timeToMinutes(fullDayStart);
        for (const interval of sortedIntervals) {
            if (currentTime < interval.hourMin) {
                // Add missing interval
                missingIntervals.push({
                    hourMin: this.minutesToTime(currentTime),
                    hourFinish: this.minutesToTime(interval.hourMin - 1),
                });
            }
            currentTime = Math.max(currentTime, interval.hourFinish);
        }
        if (currentTime < this.timeToMinutes(fullDayEnd)) {
            missingIntervals.push({
                hourMin: this.minutesToTime(currentTime),
                hourFinish: this.minutesToTime(this.timeToMinutes(fullDayEnd)),
            });
        }

        return missingIntervals;
    }

}
