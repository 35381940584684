
import { useRoutesStore } from "@/store/modules/route";
import { usePlanningStore } from "@/store/modules/planning";
import { FormVuetify, SelectData } from "@/types/common/vuetify";
import { getDaysInRange, getErrorCatch, requiredField, sleep } from "@/utils";
import { Vue, Options } from "vue-class-component";
import HeaderCommonBooking from "./HeaderCommonBooking.vue";
import BannerMobile from "@/assets/images/banners/banner_mov.png";
import { useDisplay } from "vuetify";
import { customScrollTo } from "@/utils";
import { useAuthStore } from "@/store/modules/auth";
import { useMainStore } from "@/store/modules/main";
import { RouteStationAPI } from "@/api/route-station";
import { GetZonesResponse } from "@/types/response/route";
import { LocationsApi } from "@/api/locations";
import { SearchFormAirport, useAirportBookingStore } from "@/store/modules/airportBooking";
import { GetSearchAddress } from "@/types/response/locations";
import { DateTime } from "luxon";

@Options({
  name: "FormSearchAirport",
  components: {
    HeaderCommonBooking,
  },
  props: {
    home: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
})
export default class FormSearchAirport extends Vue {
  private locationsApi = new LocationsApi()
  private airportBookingStore = useAirportBookingStore()
  public routeStore = useRoutesStore();
  public planningStore = usePlanningStore();
  private mainStore = useMainStore();
  private authStore = useAuthStore();
  public requiredField = requiredField;
  public searchLoading = false;
  public valueTempSW = false
  public mobile = false;
  public home!: boolean;
  public fromSend = false;
  public zones: GetZonesResponse[] = []
  public zoneSelected: null | string = null
  public addressSearch = null;
  public address: GetSearchAddress[] = []
  public isLoading = false;
  public timeout = null;
  public listTypeTrip: SelectData[] = [
    {
      title: "Solo ida",
      value: "justReturn",
    },
  ];;

  public banner = BannerMobile;
  public timeOut = 0;

  public dateCreateTrip: SearchFormAirport = {
    origin: undefined,
    destination: undefined,
    date: new Date(),
    typeTrip: "justReturn",
    dateFinal: new Date(),
    destinationText: '',
    bag: undefined,
    seatings: undefined
  };

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < 572;
  }

  get today() {
    return DateTime.local().set({ hour: 0, minute: 0 }).toJSDate()
  }

  get disableDateFinal() {
    return !this.dateCreateTrip.date

  }

  get isLogged() {
    return this.authStore.isLogged;
  }

  get isSigned() {
    return this.authStore.user?.isSign;
  }

  get formData(){
    return this.airportBookingStore.form
  }


  mounted(): void {
    const { mobile } = useDisplay();
    this.mobile = mobile.value;
    // rellenando datos por si existen
    if(this.formData.bag){
      this.dateCreateTrip.bag = this.formData.bag
    }
    if(this.formData.seatings){
      this.dateCreateTrip.seatings = this.formData.seatings
    }
    if(this.formData.date){
      this.dateCreateTrip.date = this.formData.date
    }
  }

  validateSign() {
    if (
      !this.authStore.isLogged) {
      this.mainStore.setNotification({
        isOpen: true,
        message:
          "Para continuar con la compra, debes iniciar sesión o crear tu cuenta.",
        isLink: true,
        link: "/login",
        nameLink: "Aquí",
        color: "red-darken-2",
        timeout: 3000,
        buttonClose: false,
      });
      return false;
    } else {
      if (!this.isSigned) {
        this.mainStore.setNotification({
          isOpen: true,
          message:
            "Para continuar con la compra, debes firmar tu contrato.",
          isLink: true,
          link: "/perfil",
          nameLink: "Aquí",
          color: "red-darken-2",
          timeout: 3000,
          buttonClose: false,
        });
        return false;
      }
    }
    return true;
  }

  async search() {
    const form = this.$refs["formSearch"] as FormVuetify;
    if (!form) return;
    const valid = await form.validate();
    this.fromSend = true;
    if (valid.valid) {
      if(!this.validateSign()){
        return;
      }
      this.searchLoading = true;
      try {
        this.airportBookingStore.setForm(this.dateCreateTrip);
        await this.airportBookingStore.getZone()
      } catch (error) {
        getErrorCatch(error)
      }
      this.searchLoading = false;
    }
  }

  beforeUnmount(): void {
    clearInterval(this.timeOut);
  }

  different(value: string, valueCompare?: string) {
    if (!valueCompare) return true;
    return value !== valueCompare ? true : "Campo igual a origen";
  }
  back() {
    if (this.mobile) {
      this.planningStore.clearBooking();
      this.dateCreateTrip = {
        date: new Date(),
        destination: undefined,
        origin: undefined,
        dateFinal: new Date(),
        typeTrip: "round",
      };
      return;
    }
    this.$router.push("/");
  }
  setTypeTrip(value: string) {
    if (value === "round") {
      this.dateCreateTrip.dateFinal = new Date();
      this.dateCreateTrip.date = new Date();
      this.planningStore.setBuyMultiple(false);
    } else {
      this.dateCreateTrip.dateFinal = undefined;
      this.dateCreateTrip.date = new Date();
    }
  }

  updateDateFinal(value: Date) {
    if (this.dateCreateTrip.typeTrip === "round") {
      this.dateCreateTrip.dateFinal = new Date(value);
    }
  }

  handleInput(value: InputEvent) {
    const valueFinal = (value.target as any).value;
    clearInterval(this.timeOut);
    this.timeOut = setTimeout(() => {
      if (valueFinal && valueFinal.length > 4) {
        this.fetchAvenidas(valueFinal);
      } else {
        this.address = [];
      }
    }, 100)

  }

  async fetchAvenidas(search: string) {
    this.isLoading = true
    try {
      const response = await this.locationsApi.searchAddress(search);
      this.address = response
    } catch (error) {
      if (!(error as any).isCancel) {
        getErrorCatch(error)
      }
    } finally {
      this.isLoading = false;
    }
  }
  setDestination(value: string) {
    const addressSelected = this.address.find(({ place_id }) => place_id == value);
    if (addressSelected) {
      this.dateCreateTrip.destinationText = addressSelected.description;
    }
  }
}
