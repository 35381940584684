export enum ROUTES_GLOBAL {
  MAIN = "contact/",
  PARAMETER = 'parameters/',
  USER = "user/",
  AUTH = "auth/",
  ROUTES_STATION = "route/",
  VEHICLES = 'vehicle/',
  PLANNING_TRIP = 'planning-trip/',
  TRIPS = 'trips/',
  COUPONS = 'coupons/',
  LOCATIONS = 'locations/',
  SECTOR = 'sector/',
  AIRPORT = 'trips/airport/'
}
