
import { VehicleAPI } from "@/api/vehicles";
import { CreateTypeVehicleRequest } from "@/types/request/vehicle";
import {
  GetBrandsResponse,
  GetModelByBrandResponse,
  GetTypesVehicleResponse,
} from "@/types/response/vehicle";
import { getErrorCatch, requiredField, min, validateNumber } from "@/utils";
import { Options, Vue } from "vue-class-component";
import ModalCommon from "../common/ModalCommon.vue";
import { useVehicleStore } from "@/store/modules/vehicle";
import { FormVuetify } from "@/types/common/vuetify";
import { ResponseCommonName } from "@/types/common/api";
@Options({
  name: "CreateTypeVehicle",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    ModalCommon,
  },
})
export default class CreateTypeVehicle extends Vue {
  public loadingCreateVehicle = false;
  private vehicleApi = new VehicleAPI();
  public validateNumber= validateNumber;
  public requiredField = requiredField;
  public isVisible!: boolean;
  public updateType : 'type-vehicle' | 'brand' | 'model' = 'type-vehicle'
  public dataCreate: CreateTypeVehicleRequest = {
    name: undefined,
    seatings :undefined
  };
  public dataCreatedModel = {
    name: undefined,
    brand : undefined
  }
  public loadingModels = false;

  public brands: GetBrandsResponse[] = [];
  public models: GetModelByBrandResponse[] = [];
  public typesVehicle: GetTypesVehicleResponse[] = [];
  
  closeModalCreateVehicle() {
    this.dataCreate = {
      name : undefined,
      seatings: undefined
    };
    this.dataCreatedModel = {
      brand : undefined,
      name : undefined
    }
    this.$emit("closeModal");
    let ref = ''
    switch (this.updateType) {
      case 'brand':
        ref = 'formCreateBrand'
        break;
      case 'model':
        ref = 'formCreateModel';
        break;
      case 'type-vehicle':
        ref = 'formCreateVehicle'
        break;
      default:
        throw new Error("Ocurrio un error")
    }
    const form = this.$refs[ref] as FormVuetify;
    if (!form) return;
    form.reset();
    this.updateType = "type-vehicle";
  }
  
  async saveVehicle() {
    let ref = ''
    switch (this.updateType) {
      case 'brand':
        ref = 'formCreateBrand'
        break;
      case 'model':
        ref = 'formCreateModel';
        break;
      case 'type-vehicle':
        ref = 'formCreateVehicle'
        break;
      default:
        throw new Error("Ocurrio un error")
    }
    const form = this.$refs[ref] as FormVuetify;
    if (!form) return;
    const validate = await form.validate();
    if (validate.valid) {
      this.loadingCreateVehicle = true;
      try {
        if(this.updateType == 'type-vehicle'){
          const data: CreateTypeVehicleRequest = {
            ...this.dataCreate,
            seatings : this.dataCreate.seatings ? parseInt(this.dataCreate.seatings as string) : undefined
          };
          await this.vehicleApi.createTypeVehicle(data);
        }else if(this.updateType == 'brand' && this.dataCreate.name !== undefined){
          await this.vehicleApi.createBrand(this.dataCreate.name);
        }else if(this.updateType == 'model' && this.dataCreatedModel.brand !== undefined && this.dataCreatedModel.name !== undefined){
          await this.vehicleApi.createModel(this.dataCreatedModel.brand, this.dataCreatedModel.name)
        }
        this.closeModalCreateVehicle();
      } catch (error) {
        getErrorCatch(error);
      }
      this.loadingCreateVehicle = false;
    }
  }

  created() {
    this.$watch("isVisible", (value: boolean) => {
      if (value) {
        this.getBrands();
        this.getTypesVehicle();
      }
    });
  }


  async getBrands() {
    try {
      this.brands = await this.vehicleApi.getBrands();
    } catch (error) {
      getErrorCatch(error);
    }
  }

  async getTypesVehicle() {
    try {
      this.typesVehicle = await this.vehicleApi.getTypesVehicle();
    } catch (error) {
      getErrorCatch(error);
    }
  }

  async getModels(brand: string) {
    if (!brand) return;
    this.loadingModels = true;
    try {
      const models = await this.vehicleApi.getModelsByBrand(brand);
      this.models = models;
    } catch (error) {
      getErrorCatch(error);
    }
    this.loadingModels = false;
  }

  setTypeCreated(typeCreated : 'type-vehicle' | 'brand' | 'model'){
    this.updateType = typeCreated;
    this.dataCreate = {
      name: undefined,
      seatings : undefined
    }
    this.dataCreatedModel = {
      brand : undefined,
      name : undefined
    }
  }
}
