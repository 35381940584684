
import { SectorApi } from '@/api/sector';
import ModalCommon from '@/components/common/ModalCommon.vue';
import { GetSectors, Hour } from '@/types/response/locations';
import { formatCurrency, getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { Header } from 'vue3-easy-data-table';


@Options({
    name: 'SectorsView',
    components : {
        ModalCommon
    }
})
export default class SectorsView extends Vue {
    public visibleCreateSector = false;
    public apiSector = new SectorApi()
    public loadingTable= false
    public items :GetSectors[] = [];
    public isVisibleDetail = false;
    public sectorHourSelected : Hour | null = null;
    public formatCurrency = formatCurrency;
    public headers: Header[] = [
        { text: "Nombre", value: "name" },
        { text: "Horas", value: "hours" },
        { text: "", value: "actions" },
    ];

    mounted(): void {
        this.getSectors()
    }

    setSectorHour(sectorHour: Hour){
        this.sectorHourSelected = sectorHour;
        this.isVisibleDetail = true
    }

    closeModalSectorHour(){
        this.sectorHourSelected = null;
        this.isVisibleDetail = false
    }

    async getSectors(){
        this.loadingTable = true;
        try {
            this.items = await this.apiSector.get()
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }
}
