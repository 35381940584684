import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menu) => {
      return (_openBlock(), _createBlock(_component_v_list, {
        nav: "",
        density: "compact",
        key: `menu_${menu.key}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_subheader, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(menu.name), 1)
            ]),
            _: 2
          }, 1024),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.items, (item, i) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: 'list-menu'+i,
              value: item,
              "active-color": "primary",
              variant: "plain",
              to: item.link,
              exact: item.exact,
              disabled: item.disabled
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: item.icon
                }, null, 8, ["icon"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, {
                  textContent: _toDisplayString(item.text)
                }, null, 8, ["textContent"])
              ]),
              _: 2
            }, 1032, ["value", "to", "exact", "disabled"]))
          }), 128))
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}