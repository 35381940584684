import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-evenly mb-md-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_ModalCommon = _resolveComponent("ModalCommon")!

  return (_openBlock(), _createBlock(_component_ModalCommon, {
    textButton: "Crear",
    onEventSuccess: _ctx.saveVehicle,
    "max-width": "1000",
    isVisible: _ctx.isVisible,
    title: "Crear vehículo",
    loading: _ctx.loadingCreateVehicle,
    onCloseModal: _ctx.closeModalCreateVehicle
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_form, { ref: "formCreateVehicle" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_btn, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setTypeCreated('default'))),
              color: _ctx.typeCreated == 'default' ? 'primary' : ''
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  color: _ctx.typeCreated == 'default' ? 'white' : 'primary'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-bus")
                  ]),
                  _: 1
                }, 8, ["color"]),
                _createTextVNode(),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.typeCreated == 'default' ? 'text-white' : 'text-primary')
                }, "Tacerca", 2)
              ]),
              _: 1
            }, 8, ["color"]),
            _createVNode(_component_v_btn, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTypeCreated('airport'))),
              color: _ctx.typeCreated == 'airport' ? 'primary' : ''
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  color: _ctx.typeCreated == 'airport' ? 'white' : 'primary'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-airplane")
                  ]),
                  _: 1
                }, 8, ["color"]),
                _createTextVNode(),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.typeCreated == 'airport' ? 'text-white' : 'text-primary')
                }, "Tacerca airport", 2)
              ]),
              _: 1
            }, 8, ["color"])
          ]),
          _createVNode(_component_v_row, { "no-gutters": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    variant: "outlined",
                    density: "comfortable",
                    label: "Marca",
                    modelValue: _ctx.brandSelected,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.brandSelected) = $event)),
                      _ctx.getModels
                    ],
                    items: _ctx.brands,
                    "item-value": "_id",
                    "item-title": "name",
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "items", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.dataCreate.model,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataCreate.model) = $event)),
                    loading: _ctx.loadingModels,
                    variant: "outlined",
                    density: "comfortable",
                    label: "Modelos",
                    disabled: _ctx.brandSelected == '',
                    items: _ctx.models,
                    "item-value": "_id",
                    "item-title": "name"
                  }, null, 8, ["modelValue", "loading", "disabled", "items"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    modelValue: _ctx.dataCreate.typeVehicle,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dataCreate.typeVehicle) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Tipo de vehículo",
                    items: _ctx.typesVehicle,
                    "item-value": "_id",
                    "item-title": "name"
                  }, null, 8, ["modelValue", "items"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.patent,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dataCreate.patent) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Patente",
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.year,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dataCreate.year) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "Año",
                    type: "number",
                    rules: [_ctx.requiredField, (value) => _ctx.min(value, 1900)]
                  }, null, 8, ["modelValue", "rules"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                md: "6",
                class: "px-2 py-md-3 py-1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: _ctx.dataCreate.gpsID,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dataCreate.gpsID) = $event)),
                    variant: "outlined",
                    density: "comfortable",
                    label: "GPS"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              (_ctx.typeCreated == 'airport')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6",
                      class: "px-2 py-md-3 py-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          modelValue: _ctx.driverSelected,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.driverSelected) = $event)),
                          "item-value": "_id",
                          "item-title": "name",
                          items: _ctx.drivers,
                          variant: "outlined",
                          density: "comfortable",
                          label: "Conductor",
                          rules: [_ctx.requiredField]
                        }, null, 8, ["modelValue", "items", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "3",
                      class: "px-2 py-md-3 py-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.dataCreate.bags,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.dataCreate.bags) = $event)),
                          label: "Maletas",
                          variant: "outlined",
                          density: "comfortable",
                          type: "number",
                          rules: [_ctx.requiredField, (value) => _ctx.min(value, 1)]
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "3",
                      class: "px-2 py-md-3 py-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_text_field, {
                          modelValue: _ctx.dataCreate.seatings,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dataCreate.seatings) = $event)),
                          variant: "outlined",
                          density: "comfortable",
                          label: "Asientos",
                          type: "number",
                          rules: [_ctx.requiredField, (value) => _ctx.min(value, 1)]
                        }, null, 8, ["modelValue", "rules"])
                      ]),
                      _: 1
                    })
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.typeCreated == 'default')
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: "12",
                    md: "6",
                    class: "px-2 py-md-3 py-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        modelValue: _ctx.dataCreate.seatings,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dataCreate.seatings) = $event)),
                        variant: "outlined",
                        density: "comfortable",
                        label: "Asientos",
                        type: "number",
                        rules: [_ctx.requiredField, (value) => _ctx.min(value, 1)]
                      }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.typeCreated == 'default')
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: "12",
                    md: "6",
                    class: "px-2 py-md-3 py-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        variant: "outlined",
                        density: "comfortable",
                        label: "Tipo de distribución",
                        modelValue: _ctx.dataCreate.typeDistribution,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.dataCreate.typeDistribution) = $event)),
                        items: _ctx.typeDistribution,
                        "item-value": "_id",
                        "item-title": "name",
                        rules: [_ctx.requiredField]
                      }, null, 8, ["modelValue", "items", "rules"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["onEventSuccess", "isVisible", "loading", "onCloseModal"]))
}