
import { formatDateSimple,convertTo12HourFormatV2 } from '@/filters/formatDate';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { GetHourPlaceId, GetHourPlaceVehicle } from '@/types/response/locations';
import { CreateTripAirport } from '@/types/request/trip';
import { Options, Vue } from 'vue-class-component';
import imageCar from '@/assets/car.png'
import { formatCurrency, getErrorCatch } from '@/utils';
import {TripAirportApi} from '@/api/trip/aiport'
import { CreateTripFreeResponse, CreateTripResponse } from '@/types/response/trip';


@Options({
    name: 'DetailSectorPayment',
    components: {}
})
export default class DetailSectorPayment extends Vue {
    public imageCar = imageCar;
    public apiAirport = new TripAirportApi()
    public airportStore = useAirportBookingStore();
    public formatDateSimple = formatDateSimple;
    public formatCurrency = formatCurrency;
    public convertTo12HourFormatV2 = convertTo12HourFormatV2;
    public hours: string[] = []
    public loadingCreated= false;
    public typePayment : 'oneclick' | 'webpay' = 'oneclick'
    public vehiclesAvailable: GetHourPlaceVehicle[] = [];

    get step() {
        return this.airportStore.step
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get emptyResult() {
        return this.airportStore.emptyResult
    }

    get vehicleAiportSelected() {
        return this.airportStore.vehicleAiportSelected
    }

    get hourSelected() {
        return this.airportStore.hourSelected
    }



    timeToMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    minutesToTime(minutes: number) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
    }

    generateTimeIntervals(hourMin: string, hourFinish: string, currentTime?: string) {
        const intervals: string[] = [];
        let startMinutes = this.timeToMinutes(hourMin);
        const endMinutes = this.timeToMinutes(hourFinish);

        if (currentTime) {
            const currentMinutes = this.timeToMinutes(currentTime);
            if (startMinutes < currentMinutes + 15) {
                startMinutes = Math.ceil((currentMinutes + 15) / 15) * 15;
            }
        }

        for (let minutes = startMinutes; minutes < endMinutes; minutes += 15) {
            intervals.push(this.minutesToTime(minutes));
        }

        return intervals;
    }

    getAllowedTimeSlots(data: GetHourPlaceId[], currentTime?: string) {
        return data
            .filter((slot) => !slot.isBlocked)
            .flatMap((slot) => this.generateTimeIntervals(slot.hourMin, slot.hourFinish, currentTime));
    }

    toBack(){
        this.airportStore.backSelectedVehicle();
    }

    async toPay(){
        if(!this.vehicleAiportSelected){
            return;
        }
        this.loadingCreated= true
        try {
            const {date,bag,seatings,destination} = this.dataForm
            const data: CreateTripAirport = {
                bags : parseInt(bag as string),
                date : date.toISOString(),
                hourIdSector: this.vehicleAiportSelected._id,
                placeid: destination as string,
                seatings: parseInt(seatings as string),
                paymentEntry : {
                    typePayment: this.typePayment
                }
            }
            const responseCreateTrip = await this.apiAirport.createTripAirport(data)
            if(this.typePayment == 'oneclick'){
                if((responseCreateTrip as CreateTripFreeResponse).code){
                    this.$router.push('/airport?confirm='+ (responseCreateTrip as CreateTripFreeResponse).code)
                }else{
                    throw new Error('Error al crear el viaje')
                }
            }else if(this.typePayment == 'webpay'){
                if((responseCreateTrip as CreateTripResponse).token){
                    window.location.href = `${(responseCreateTrip as CreateTripResponse).url}?token=${(responseCreateTrip as CreateTripResponse).token}`
                }else{
                    throw new Error('Error al crear el viaje')
                }
            }
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingCreated= false
    }
}
