
import { TripApi } from '@/api/trip';
import { GetStatusTripResponse } from '@/types/response/trip';
import { Options, Vue } from 'vue-class-component';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import LocationPayment from '@/assets/images/icons/location-payment.svg'
import Line from '@/assets/images/icons/linea.svg'
import { convertTo12HourFormatV2, getDate } from '@/filters/formatDate';
import { formatCurrency } from '@/utils';
import PayAuto from '@/assets/images/payments/cargo-automatico-sf.png'
import WebPay from '@/assets/images/payments/wp-sf.svg'
import Mach from '@/assets/images/payments/mach-sf.png'

@Options({
    name: 'DetailTripConfirm',
    components: {
        HeaderCommonBooking
    }
})
export default class BookingPage extends Vue {
    private tripApi = new TripApi();
    public PayAuto = PayAuto;
    public WebPay = WebPay; 
    public Mach= Mach;
    public loading = false;
    public tripResponse: GetStatusTripResponse | null = null
    public errorTrip = false;
    public messageError = '';
    public LocationPayment = LocationPayment;
    public Line = Line;
    public convertTo12HourFormat = convertTo12HourFormatV2;
    public formatCurrency = formatCurrency;
    public getDate = getDate;
    public activeTrip: 'outbound' | 'return' = 'outbound'

    get confirmQuery() {
        return this.$route.query['confirm'];
    }


    get seatings() {
        if (!this.tripResponse) return ''
        const seatingOwner = this.tripResponse.seating;
        const seatingPassengers = this.tripResponse.passengers.map(({ seating }) => seating)
        seatingPassengers.push(seatingOwner)
        seatingPassengers.sort(this.sortSeatings)
        return seatingPassengers.join(",")
    }

    get seatingsReturn() {
        if (!this.tripResponse) return ''
        const seatingOwner = this.tripResponse.returnData?.seatingOwner || '';
        const seatingPassengers: string[] = this.tripResponse.returnData ? this.tripResponse.returnData.passengers : []
        seatingPassengers.push(seatingOwner);
        seatingPassengers.sort(this.sortSeatings);
        return seatingPassengers.join(",");
    }

    get fullNameOwner() {
        if (this.tripResponse) {
            const { owner: { firstName, lastName } } = this.tripResponse
            return `${firstName} ${lastName}`
        }
        return ''
    }

    get addressFormat(){
        if (!this.tripResponse || !this.tripResponse.dataLocationAirport) {
            return '';
        }
        const {descAddress} = this.tripResponse.dataLocationAirport;
        return `${descAddress.route} ${descAddress.street_number}, ${descAddress.locality}`
    }


    async getTrip() {
        if (!this.confirmQuery) return;
        this.loading = true;
        try {
            const code = this.confirmQuery as string;
            this.tripResponse = await this.tripApi.getTrip(code);
        } catch (error) {
            this.errorTrip = true;
            this.tripResponse = null
            this.messageError = (error as Error).message as string;
        }
        this.loading = false;
    }



    sortSeatings(a: string, b: string) {
        return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
    }

    mounted() {
        this.getTrip()
    }

    back() {
        this.$router.push('/booking')
    }

    setActiveTrip(active: 'outbound' | 'return' = 'outbound') {
        this.activeTrip = active;
    }

    isActiveTrip(id: string) {
        if (!this.tripResponse) return false;
        return this.tripResponse._id == id;
    }

    setTrip(trip: GetStatusTripResponse){
        this.tripResponse = trip
    }

    getImagePayment(type: string){
        if(type == 'Oneclick'){
            return this.PayAuto
        }else if(type == 'Webpay'){
            return this.WebPay
        }else if(type == 'Mach'){
            return this.Mach
        }
        return ''
    }
}
