

import FormSearchAirport from '@/components/Booking/FormSearchAirport.vue';
import { useRoutesStore } from '@/store/modules/route';
import { Vue, Options } from 'vue-class-component';
import BannerInfoSearch from '@/components/Booking/BannerInfoSearch.vue';
import { usePlanningStore } from '@/store/modules/planning';
import DetailTripAirport from '@/components/Booking/DetailTripAirport.vue';
import { GetStatusTripResponse } from '@/types/response/trip';
import '@/scss/booking.scss'
import { useMainStore } from '@/store/modules/main';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import DetailSector from '@/components/Booking/DetailSector.vue';
import DetailSectorPayment from '@/components/Booking/DetailSectorPayment.vue';

@Options({
    name: 'BookingAirportPage',
    components: {
        FormSearchAirport,
        BannerInfoSearch,
        DetailTripAirport,
        DetailSector,
        DetailSectorPayment
    }
})
export default class BookingAirportPage extends Vue {
    public routeStore = useRoutesStore();
    public planningStore = usePlanningStore();
    public airportStore = useAirportBookingStore();
    public mainStore = useMainStore();
    public messageError = '';
    public tripResponse: GetStatusTripResponse | null = null

    get searchSelected() {
        return this.planningStore.searchSelected;
    }

    get confirmQuery() {
        return this.$route.query['confirm'];
    }

    get step() {
        return this.airportStore.step;
    }


    
    beforeUnmount(): void {
        this.planningStore.clearBooking()
    }
}
