export enum VEHICLE_ENDPOINTS {
  CREATE_VEHICLE = "create-vehicle",
  CREATE_VEHICLE_AIRPORT = 'create-vehicle-airport',
  CREATE_TYPE_VEHICLE = "create-type-vehicle",
  GET_TYPES_VEHICLE = "get-types-vehicle",
  CREATE_BRAND = "create-brand",
  CREATE_MODEL = "create-model",
  GET_BRANDS = "get-brands",
  GET_ALL_MODELS = "get-all-models",
  DELETE_MODEL = "delete-model/",
  GET_MODELS = "get-models/",
  GET_VEHICLES = "",
  GET_VEHICLE_PATENT = 'get-by-patent/',
  DELETE_VEHICLE = 'delete-vehicle/',
  GET_ALL_PATENTS = '/get-all-patens',
  UPDATE_VEHICLE = '/update-vehicle/'
}
