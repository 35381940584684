import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { CreateTripAirport } from "@/types/request/trip";
import { TRIP_ENDPOINTS } from "./endpoints";
import { CreateTripFreeResponse, CreateTripResponse } from "@/types/response/trip";

export class TripAirportApi {
  private request = new API(ROUTES_GLOBAL.AIRPORT);
  private api: AxiosInstance = this.request.getInstance();

  
    async createTripAirport(createTripAirport: CreateTripAirport){
      const {data} = await this.api.post<CreateTripResponse | CreateTripFreeResponse>(TRIP_ENDPOINTS.CREATE_TRIP_AIRPORT, createTripAirport);
      return data;
    }
  
}