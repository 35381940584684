import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "booking",
  id: "home"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailTripAirport = _resolveComponent("DetailTripAirport")!
  const _component_FormSearchAirport = _resolveComponent("FormSearchAirport")!
  const _component_DetailSector = _resolveComponent("DetailSector")!
  const _component_DetailSectorPayment = _resolveComponent("DetailSectorPayment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.confirmQuery)
      ? (_openBlock(), _createBlock(_component_DetailTripAirport, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.step == 'search' || _ctx.step == 'selected-sector')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_FormSearchAirport),
                _createVNode(_component_DetailSector)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.step == 'payment')
            ? (_openBlock(), _createBlock(_component_DetailSectorPayment, { key: 1 }))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}