import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import {
  CreateMassiveTripsDTO,
  CreateTripAirport,
  CreateTripRequest,
  GetTripsPagination,
  GpsTripResponse,
  ResendEmail,
  TypeCancelTrip,
} from "@/types/request/trip";
import { TRIP_ENDPOINTS } from "./endpoints";
import {
  CreateTripFreeResponse,
  CreateTripResponse,
  GetMassiveTripResponse,
  GetPassengersTrip,
  GetStatusTripResponse,
  GetTripPaginationResponse,
} from "@/types/response/trip";

export class TripApi {
  private request = new API(ROUTES_GLOBAL.TRIPS);
  private api: AxiosInstance = this.request.getInstance();

  async createTripMassive(createTripRequest: CreateMassiveTripsDTO) {
    const { data } = await this.api.post<
      CreateTripResponse | CreateTripFreeResponse
    >(
      TRIP_ENDPOINTS.CREATE_TRIP_MASSIVE,
      createTripRequest
    );
    return data;
  }

  async createTrip(createTripRequest: CreateTripRequest, isAuth = false) {
    const { data } = await this.api.post<
      CreateTripResponse | CreateTripFreeResponse
    >(
      isAuth ? TRIP_ENDPOINTS.CREATE_TRIP_AUTH : TRIP_ENDPOINTS.CREATE_TRIP,
      createTripRequest
    );
    return data;
  }
  
  async createTripAdmin(createTripRequest: CreateTripRequest) {
    const { data } = await this.api.post<
      CreateTripResponse | CreateTripFreeResponse
    >(TRIP_ENDPOINTS.CREATE_TRIP_ADMIN, createTripRequest);
    return data;
  }

  async getMassiveTrip(id: string){
    const { data } = await this.api.get<GetMassiveTripResponse>(
      TRIP_ENDPOINTS.GET_TRIP + id + "?isMassive=true"
    );
    return data;
  }

  async getTrip(code: string) {
    const { data } = await this.api.get<GetStatusTripResponse>(
      TRIP_ENDPOINTS.GET_TRIP + code
    );
    return data;
  }

  async getTrips({
    limit,
    page,
    code,
    customer,
    dateFinish,
    dateInit,
    planning,
    warningTrip,
  }: GetTripsPagination, isNews = false) {
    let URL = `${TRIP_ENDPOINTS.GET_TRIPS}?page=${page}&limit=${limit}`;
    if(isNews){
      URL+=`&isNew=${true}`
    }
    if (code) {
      URL += "&code=" + code;
    }
    if (customer) {
      URL += "&customer=" + customer;
    }
    if (planning) {
      URL += "&planningTrip=" + planning;
    }
    if (dateFinish && dateInit) {
      URL += "&dateFinish=" + dateFinish + "&dateInit=" + dateInit;
    }
    if (warningTrip) {
      URL += "&warningTrip=true";
    }
    const { data } = await this.api.get<GetTripPaginationResponse>(URL);
    return data;
  }
  async resendEmail({ typeResend, email, code }: ResendEmail) {
    await this.api.post(TRIP_ENDPOINTS.RESEND_EMAIL + code, {
      typeResend,
      email,
    });
  }

  async cancelTrip(id: string, typeCancel?: TypeCancelTrip) {
    let URL = TRIP_ENDPOINTS.CANCEL_TRIP + id;
    if (typeCancel) {
      URL += "?cancelTypeTrip=" + typeCancel;
    }
    const { data } = await this.api.delete<string>(URL);
    return data;
  }

  async validateVehicle(id: string, typeTrip?: string) {
    let url = TRIP_ENDPOINTS.VALIDATE_GPS_TRIP + id;
    if (typeTrip && typeTrip !== '') {
      url += `?tipo=${typeTrip}`
    }
    const { data } = await this.api.get<GpsTripResponse>(url);
    return data;
  }

  async validateVehicleTrip(id: string, trip: string, typeTrip?: string) {
    let url = TRIP_ENDPOINTS.VALIDATE_GPS_TRIP + id + "?trip=" + trip;
    if (typeTrip && typeTrip !== '') {
      url += `&tipo=${typeTrip}`
    }
    const { data } = await this.api.get<GpsTripResponse>(url);
    return data;
  }

  async getPassengers(code: string) {
    const { data } = await this.api.get<GetPassengersTrip>(`/${code}/passengers`)
    return data;
  }

  async setStationTrip(id: string, origin: string, destination : string, isReturn = false){
    await this.api.patch(TRIP_ENDPOINTS.SET_STATION_TRIP+id,{origin, destination, isReturn})
  }
}
