
import { formatDateCompleted } from '@/filters/formatDate';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import {  GetPlaceIdRes } from '@/types/response/locations';
import { Options, Vue } from 'vue-class-component';
import imageCar from '@/assets/car.png'
import { formatCurrency } from '@/utils';

@Options({
    name: 'DetailSector',
    components: {}
})
export default class DetailSector extends Vue {
    public imageCar = imageCar;
    public airportStore = useAirportBookingStore();
    public formatDateCompleted = formatDateCompleted;
    public formatCurrency= formatCurrency;
    public hourSelected: string | null = null;
    public hours: string[] = []
    
    public vehiclesAvailable : GetPlaceIdRes[] = [];

    get step() {
        return this.airportStore.step
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get emptyResult() {
        return this.airportStore.emptyResult
    }


    timeToMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    minutesToTime(minutes: number) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
    }

    generateTimeIntervals(hourMin: string, hourFinish: string, currentTime?: string) {
        const intervals: string[] = [];
        let startMinutes = this.timeToMinutes(hourMin);
        const endMinutes = this.timeToMinutes(hourFinish);
        if (currentTime) {
            const currentMinutes = this.timeToMinutes(currentTime);
            if (startMinutes < currentMinutes + 15) {
                startMinutes = Math.ceil((currentMinutes + 15) / 15) * 15;
            }
        }

        for (let minutes = startMinutes; minutes < endMinutes; minutes += 15) {
            intervals.push(this.minutesToTime(minutes));
        }

        return intervals;
    }

    setVehicle(data : GetPlaceIdRes){
        this.airportStore.setVehicleAiport(data);
    }
}
