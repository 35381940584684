import { LocationsApi } from "@/api/locations";
import { GetHourPlaceVehicle, GetPlaceIdRes } from "@/types/response/locations";
import { defineStore } from "pinia";

export interface SearchFormAirport{
    origin ?: string;
    destination?: string;
    date: Date ;
    dateFinal?:Date
    typeTrip : 'justReturn' | 'round'
    destinationText?:string
    bag?:string | number;
    seatings?:string | number;
}

export interface AirportBookingStore{
    form : SearchFormAirport,
    placeIdSelected: string,
    placeInfo: GetPlaceIdRes[]
    step: 'search' | 'selected-sector' | 'payment',
    emptyResult : boolean
    vehicleAiportSelected: GetPlaceIdRes | null
    hourSelected : string
}

export const useAirportBookingStore = defineStore("airportBooking", {
    state: (): AirportBookingStore => ({
        form : {
            date : new Date(),
            destination : '',
            origin: '',
            typeTrip : 'justReturn',
            bag : '',
            seatings : ''
        },
        placeIdSelected : '',
        placeInfo : [],
        step : 'search',
        emptyResult  : false,
        vehicleAiportSelected : null,
        hourSelected : ''
    }),
    actions : {
        setVehicleAiport(data: GetPlaceIdRes){
            this.vehicleAiportSelected = data;
            this.step = 'payment'
        },
        setStep(step: 'search' | 'selected-sector' | 'payment' ){
           this.step = step 
        },
        backSelectedVehicle(){
            this.step = 'selected-sector';
            this.hourSelected = '';
            this.vehicleAiportSelected = null;
        },
        async getZone(){
            this.emptyResult = false;
            if(!this.form.destination){
                throw new Error("Destination not found")
            }
            const locationApi = new LocationsApi()
            this.placeInfo = await locationApi.getZone(this.form)
            if(this.placeInfo == null){
                this.emptyResult = true;
            }
            this.placeIdSelected = this.form.destination
            this.step = 'selected-sector'
        },
        setForm(data : SearchFormAirport){
            this.form = data;
        }
    }
})